.bigWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.container {
  max-width: 400px;
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #94a4b4;
}

.headerInfo {
  color: white;
  font-family: "Staatliches";
  font-size: 1.5rem;
}

.field {
  font-family: "Inconsolata", monospace;
  resize: vertical;
  outline: none;
  border: none;
  margin-top: 10px;
  padding: 10px;
  font-size: 1.2rem;
}

.buttonWrapper {
  display: flex;
  justify-content: space-around;
}

.formButton {
  background-color: #94a4b4;
  color: #222327;
  font-family: "Staatliches";
  flex-grow: 1;
  margin: 10px;
}


.formButton:hover {
  cursor: pointer;
  background-color: white;
}

input[type="number"] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  text-align: center;
  color: indianred;
  font-family: "Staatliches";
  margin-top: 15px;
}

.ok {
  text-align: center;
  color: aqua;
  font-family: "Staatliches";
  margin-top: 15px;
}
