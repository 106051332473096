body,
html {
  padding: 0;
  margin: 0;
  background-color: #22262a;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #97bfd3;
  border-radius: 10px;
}

@keyframes fade {
  0% {
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes leftToRight {
  0% {
    transform: translateZ(0);
    transform: translateY(100px);
    transform: translate3d(0);
  }
  100% {
    transform: translateZ(0);
    transform: translate3d(0);
    transform: translateY(0);
  }
}
