.main {
  width: 100%;
  background-color: #22262a;
  text-align: center;
  user-select: none;
  position: relative;
  padding-bottom: 75px;
}

.aboutWrapper {
  animation: 1s ease-out 0s 1 fade;
  display: block;
  text-align: center;
  margin: auto;
}

.brandName {
  display: inline-block;
  margin-top: 14px;
  font-size: 36px;
  font-family: "Sacramento", cursive;
  text-decoration: none;
  color: #0eff7d;
  font-weight: bolder;
  filter: brightness(150%);
}

.header {
  margin-top: 54px;
  color: white;
  font-family: "Staatliches";
  font-size: 48px;
  letter-spacing: 4px;
  display: block;
  padding: 0 10px;
}

.about {
  color: #94a4b4;
  font-family: "Staatliches";
  letter-spacing: 1px;
  padding: 0 15px;
  font-size: 20px;
}

.info {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 0;
}


@media screen and (min-width: 680px) {
  .header {
    font-size: calc(48px + 52 * ((100vw - 680px) / 320));
  }
  .about {
    font-size: calc(20px + 10 * ((100vw - 680px) / 320));
  }
}
@media screen and (min-width: 1000px) {
  .header {
    font-size: 100px;
  }
  .about {
    font-size: 30px;
  }
}

span {
  display: inline-block;
}
